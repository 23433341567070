<template>
  <div>
    <div class="search">
      <div class="title">核销点列表</div>
      <hr />
      <el-form :inline="true" class="top">
        <el-form-item label="核销点名称:">
          <el-input v-model="params.nickName"></el-input>
        </el-form-item>
        <el-form-item label="状态:">
          <el-select v-model="params.status" placeholder="请选择">
            <el-option label="全部" :value="0"></el-option>
            <el-option label="正常" :value="1"></el-option>
            <el-option label="禁用" :value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item >
          <el-button type="primary" @click="getList">查询</el-button>
          <el-button type="primary" @click="reset">重置</el-button>
          <el-button type="primary" v-if="status != 2" @click="add">添加</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      border
      tooltip-effect="dark"
      :row-key="rowKey"
      style="width: 100%"
      align="center"
      @selection-change="handleSelectionChange"
    >
      <el-table-column v-if="status == 2" type="selection" width="55" :reserve-selection="true" align="center"> </el-table-column>
      <el-table-column type="index" width="50" align="center"> </el-table-column>
      <el-table-column prop="redeemName" label="核销点名称" align="center">
      </el-table-column>

      <el-table-column prop="address" label="地址" width="200" align="center">
      </el-table-column>
      <el-table-column prop="strStatus" label="状态" width="180" align="center">
      </el-table-column>
      <el-table-column prop="addTime" label="添加时间" width="200" align="center">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <div slot-scope="scope" v-if="status == 2">
          <el-button
            type="success"
            size="mini"
            @click="$emit('submit', {redeemId: scope.row.redeemId,redeemName:scope.row.redeemName})"
            
            >添加</el-button
          >
        </div>
        <div slot-scope="scope" v-else>
          <el-button
            type="success"
            size="mini"
            @click="upData(scope.row, 1)"
            v-if="scope.row.status == 2"
            >启用</el-button
          >
          <el-button
            type="warning"
            size="mini"
            @click="upData(scope.row, 2)"
            v-if="scope.row.status == 1"
            >禁用</el-button
          >
          <el-button type="primary" size="mini" @click="edit(scope.row)">编辑</el-button>
          <el-button type="primary" size="mini" @click="upData(scope.row, 0)"
            >删除</el-button
          >
        </div>
        
      </el-table-column>
    </el-table>
    <div class="block">
      <!-- <div>
              <el-button type="primary" @click="allCheckVip">批量修改vip</el-button>
            <el-button type="primary" @click="allOpe">批量操作</el-button>
         </div> -->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="params.pageSize"
          layout="total,sizes,prev,pager,next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form :model="addList" :rules="addRules" ref="addRuleFormRef">
        <el-form-item label="核销点名称:" prop="redeemName">
          <el-input v-model="addList.redeemName"></el-input>
        </el-form-item>
        <el-form-item label="地址:" prop="address">
          <el-input
            v-model="addList.address"
            type="textarea"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  writeOffList,
  writeOffListAdd,
  writeOffListUpdata,
} from "../../../api/administrator.js";
export default {
  props: {
    status: [Number, String],
  },
  data() {
    return {
      dialogVisible: false,
      total: 0,
      params: {
        currentPage: 1,
        pageSize: 5,
        redeemName: "",
        status: 0,
      },
      addList: {
        redeemId: 0,
        redeemName: "",
        address: "",
      },
      addRules: {
        redeemName: [{ required: true, message: "请输入核销点名称", trigger: "blur" }],
        address: [{ required: true, message: "请输入地址", trigger: "blur" }],
      },
      tableData: [],
      date: null,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    rowKey(row) {
      return row.redeemId;
    },
    handleSelectionChange(val) {
      this.idsOptions = val.map(item=>{return {redeemId:item.redeemId,redeemName:item.redeemName}});
    },
    packSubmit(){
      return this.idsOptions;
    },
    async getList() {
      //   this.params.beginTime = this.date ? this.date[0] : "";
      //   this.params.endTime = this.date ? this.date[1] : "";
      const { data } = await writeOffList(this.params);
      if (data.code == 0) {
        this.tableData = data.list;
        this.total = data.pagination.total;
      } else {

      }
    },
    handleSizeChange(e) {
      this.params.pageSize = e;
      this.getList();
    },
    // 页数改变
    handleCurrentChange(e) {
      this.params.currentPage = e;
      this.getList();
    },
    reset() {
      this.params.currentPage = 1;
      this.params.pageSize = 5;
      this.params.status = 0;
      this.params.redeemName = "";

      this.date = null;
      this.getList();
    },
    add() {
      this.dialogVisible = true;
    },
    edit(row) {
      Object.keys(this.addList).forEach((key) => {
        this.addList[key] = row[key];
      });
      this.dialogVisible = true;
    },
    upData(row, status) {
      let str = "";
      switch (status) {
        case 0:
          str = "删除";
          break;
        case 1:
          str = "禁用";
          break;
        case 2:
          str = "启用";
          break;
      }
      this.$confirm("是否确认" + str + "?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await writeOffListUpdata({
            redeemId: row.redeemId,
            status,
          });
          if (data.code == 0) {
            this.$message.success(data.msg);
            this.getList();
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {});
    },
    submit() {
      this.$refs.addRuleFormRef.validate(async (valid) => {
        if (valid) {
          if (this.load) return;
          this.load = true;
          const { data } = await writeOffListAdd(this.addList);
          this.load = false;
          if (data.code == 0) {
            this.$message.success(data.msg);
            this.dialogVisible = false;
            this.getList();
          } else {
            this.$message.error(data.msg);
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top {
  margin-top: 20px;
}
.block {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>
